import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getEvents = createAsyncThunk('events/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/events/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveEvent = createAsyncThunk('event/save', async (event, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.put(`/api/events/${event.id}/`, event)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createEvent = createAsyncThunk('event/create', async (event, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.post('/api/events/', event)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const deleteEvent = createAsyncThunk('event/delete', async (event, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.delete(`/api/events/${event.id}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
