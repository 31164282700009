import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/userSlice'
import projectsReducer from './features/project/projectsSlice'
import objectsReducer from './features/obj/objectsSlice'
import contragentsReducer from './features/contragent/contragentSlice'
import incomesReducer from './features/income/incomesSlice'
import paymentsReducer from './features/payment/paymentsSlice'
import expressPaymentsReducer from './features/expressPayment/expressPaymentsSlice'
import approvesReducer from './features/approve/approvesSlice'
import paymentConditionsReducer from './features/paymentCondition/paymentConditionsSlice'
import attachmentsReduces from './features/attachment/attachmentsSlice'
import executionReducer from './features/execution/executionSlice'
import bonusesReducer from './features/bonus/bonusSlice'
import tzReducer from './features/tz/tzSlice'
import blockReducer from './features/block/blockSlice'
import woodDoorReducer from './features/wooddoor/woodDoorSlice'
import workTzReducer from './features/workTz/workTzSlice'
import metalDoorReducer from './features/metaldoor/metalDoorSlice'
import furnitureReducer from './features/furniture/furnitureSlice'
import purchaseReducer from './features/purchase/purchaseSlice'
import demandReducer from './features/demand/demandSlice'
import taskReducer from './features/task/taskSlice'
import workflowReducer from './features/workflow/workflowSlice'
import taskCommentReducer from './features/task/taskCommentSlice'
import costitemsReducer from './features/projectPlan/costItemPlan/costItemSlice'
import incomePlanReducer from './features/projectPlan/incomePlan/incomePlanSlice'
import keyDateReducer from './features/projectPlan/keyDate/keyDateSlice'
import eventReducer from './features/event/eventsSlice'

//import {createLogger} from 'redux-logger';

// initialize userToken from local storage

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: null,
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

//const store = createStore(changeState)

const store = configureStore({
  reducer: {
    ui: changeState,
    user: userReducer,
    projects: projectsReducer,
    objects: objectsReducer,
    contragents: contragentsReducer,
    incomes: incomesReducer,
    payments: paymentsReducer,
    expressPayments: expressPaymentsReducer,
    approves: approvesReducer,
    paymentConditions: paymentConditionsReducer,
    attachments: attachmentsReduces,
    executions: executionReducer,
    bonuses: bonusesReducer,
    tzs: tzReducer,
    blocks: blockReducer,
    woodDoors: woodDoorReducer,
    workTzs: workTzReducer,
    metalDoors: metalDoorReducer,
    furniture: furnitureReducer,
    purchases: purchaseReducer,
    demands: demandReducer,
    tasks: taskReducer,
    workflows: workflowReducer,
    taskComments: taskCommentReducer,
    costItems: costitemsReducer,
    incomePlans: incomePlanReducer,
    keyDates: keyDateReducer,
    events: eventReducer,
  },
  /*middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger())
  ]*/
})

export default store
