import { createSlice } from '@reduxjs/toolkit'
import {
  getIncomePlans,
  saveIncomePlan,
  createIncomePlan,
  deleteIncomePlan,
} from './incomePlanActions'

const initialState = {
  incomePlans: [],
  loading: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedIncomePlan: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const incomePlansSlice = createSlice({
  name: 'incomePlans',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedIncomePlan = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getIncomePlans.fulfilled, (state, { payload }) => {
        state.loading = false
        state.incomePlans = payload
        /*payload.forEach((i) => {
          state.incomePlans[i.id] = i
        })*/
      })
      .addCase(getIncomePlans.pending, (state) => {
        state.loading = true
        state.loadingError = null
        state.savingError = null
      })
      .addCase(getIncomePlans.rejected, (state, { payload }) => {
        state.loading = false
        state.loadingError = payload
      })
      .addCase(saveIncomePlan.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveIncomePlan.fulfilled, (state, { payload }) => {
        state.savingError = null
        //state.incomePlans[state.incomePlans.findIndex((p) => p.id === payload.id)] = payload
        state.savedIncomePlan = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveIncomePlan.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createIncomePlan.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createIncomePlan.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedIncomePlan = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createIncomePlan.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteIncomePlan.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteIncomePlan.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteIncomePlan.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
    /*.addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )*/
  },
})

export const { clear } = incomePlansSlice.actions
export default incomePlansSlice.reducer
