import useAxios from '../../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getKeyDates = createAsyncThunk('keydates/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/keydates/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveKeyDate = createAsyncThunk(
  'keydate/save',
  async (keydate, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(`/api/keydates/${keydate.id}/`, keydate)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createKeyDate = createAsyncThunk(
  'keydate/create',
  async (keydate, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/keydates/', keydate)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteKeyDate = createAsyncThunk(
  'keydate/delete',
  async (keydate, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/keydates/${keydate.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
