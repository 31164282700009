import { createSlice } from '@reduxjs/toolkit'
import { getEvents, saveEvent, createEvent, deleteEvent } from './eventActions'

const initialState = {
  events: [],
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedEvent: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedEvent = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getEvents.fulfilled, (state, { payload }) => {
        state.loading = false
        state.events = payload
      })
      .addCase(getEvents.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(saveEvent.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveEvent.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.events[state.events.findIndex((p) => p.id === payload.id)] = payload
        state.savedEvent = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveEvent.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createEvent.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createEvent.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedEvent = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createEvent.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteEvent.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteEvent.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteEvent.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(isRejectedAction, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { clear } = eventsSlice.actions
export default eventsSlice.reducer
