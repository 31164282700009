import { createSlice } from '@reduxjs/toolkit'
import { getKeyDates, saveKeyDate, createKeyDate, deleteKeyDate } from './keyDateActions'

const initialState = {
  keyDates: [],
  loading: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedKeyDate: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const keyDatesSlice = createSlice({
  name: 'keyDates',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedKeyDate = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getKeyDates.fulfilled, (state, { payload }) => {
        state.loading = false
        state.keyDates = payload
        /*payload.forEach((i) => {
          state.keyDates[i.id] = i
        })*/
      })
      .addCase(getKeyDates.pending, (state) => {
        state.loading = true
        state.loadingError = null
        state.savingError = null
      })
      .addCase(getKeyDates.rejected, (state, { payload }) => {
        state.loading = false
        state.loadingError = payload
      })
      .addCase(saveKeyDate.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveKeyDate.fulfilled, (state, { payload }) => {
        state.savingError = null
        //state.keyDates[state.keyDates.findIndex((p) => p.id === payload.id)] = payload
        state.savedKeyDate = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveKeyDate.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createKeyDate.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createKeyDate.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedKeyDate = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createKeyDate.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteKeyDate.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteKeyDate.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteKeyDate.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
    /*.addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )*/
  },
})

export const { clear } = keyDatesSlice.actions
export default keyDatesSlice.reducer
